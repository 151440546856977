import { makeStyles } from "@material-ui/styles"
import React from "react"
// import bodyImage from "assets/body_image.jpg"

const useStyles = makeStyles({
  root: {
    backgroundImage: "url(./assets/background.jpg)",
    margin: "0 auto",
    textAlign: "center",
  },
  bodyImage: {
    maxHeight: "100vh",
    minHeight: "100vh",
  },
})

function App() {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <img
        src='./assets/body_image.jpg'
        className={classes.bodyImage}
        alt='Website under construction, contact James at 07932 452 920 or james@keystoneceramics.co.uk'
      />
    </div>
  )
}

export default App
